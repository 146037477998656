import React from "react";
import MainWrap from "../../components/MainWrap";
import YearsInBusiness from "../../components/YearsInBusiness";
import Link from "gatsby-link";
import SalesSubHead from "../../components/SalesSubHead";
import "./sales.css";

const settings = {
  name: "Sales",
  img1: "sales.jpg",
  subhead: "Helicopter Sales",
  subheadIndex: 3,
  title: "Helicopter Sales from Bijan Air, Inc.",
  heading: "Helicopter Sales from Bijan Air, Inc."
};

const SalesPage = ({ location }) => {
  const Subheader = props => <SalesSubHead {...props} />;
  return (
    <MainWrap settings={settings} Subheader={Subheader} location={location}>
      <p>
        All helicopters sold through Bijan Air, Inc. must first meet our high
        standards for maintenance and quality which we developed over{" "}
        <YearsInBusiness /> years in the business. We have an impeccable safety
        record maintained by requiring that each helicopter we sell pass our
        thorough safety checks and inspections.
      </p>
      <p>
        Certified since 2000 as the Authorized Sales and Service Center for the
        states of Michigan, Ohio, and Indiana, we offer the full line of
        Schweizer Helicopters as well as a range of quality used helicopters
        from manufacturers such as Sikorsky and Bell.
      </p>
      <p>
        You can purchase your new or used Schweizer helicopter directly from
        Bijan Air with confidence that you have selected a safe, and
        user-friendly helicopter with great factory product support.
      </p>
      Begin by{" "}
      <Link to="/sales/helicopters/for_sale">
        seeing our helicopter inventory
      </Link>
      , <Link to="/sales/learn">learning about new helicopter models</Link> or{" "}
      <Link to="/contact">contacting us about new or used helicopters</Link>.
    </MainWrap>
  );
};

export default SalesPage;
