import React from "react";
import SubHead from "./SubHead/";
import Link from "gatsby-link";

const SalesSubHead = ({ settings, data }) => (
  <SubHead settings={settings}>
    <Link to="/sales/learn" className="sales_button float_left">
      New
    </Link>
    <Link to="/sales/helicopters/for_sale" className="sales_button float_right">
      Inventory
    </Link>
  </SubHead>
);

export default SalesSubHead;
